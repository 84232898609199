import Lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light'
import { debounce } from '@solid-primitives/scheduled'

// Keep track of all animations
let currentAnimations: AnimationItem[] = []

const offset = 0

const viewportHeight = window.innerHeight

initializeLottieElements()

function initializeLottieElements() {
	document.querySelectorAll('.lottie').forEach(element => {
		const animationDataDesktop = element.getAttribute('data-desktop')
		if (animationDataDesktop === null) {
			throw new Error('missing desktop json file.')
		}
		const animationDataTablet = element.getAttribute('data-tablet')
		if (animationDataTablet === null) {
			throw new Error('missing tablet json file.')
		}
		const animationDataMobile = element.getAttribute('data-mobile')
		if (animationDataMobile === null) {
			throw new Error('missing mobile json file.')
		}
		const jsonFile = breakpoint(animationDataMobile, animationDataTablet, animationDataDesktop)

		const animation = Lottie.loadAnimation({
			container: element, // the dom element that will contain the animation
			renderer: 'svg',
			loop: false,
			autoplay: false,
			assetsPath: '',
			path: jsonFile, // the path to the animation json from breakpoint funktion
		})

		currentAnimations.push(animation)
	})
}

/**
 * Reinitialize all lottie elements and destroy the old ones
 */
function reinitializeLottieElements() {
	currentAnimations.forEach(animation => {
		animation.goToAndStop(0, true)
		animation.destroy()
	})

	currentAnimations = []

	initializeLottieElements()
}

function breakpoint(mobile: string, tablet: string, desktop: string): string {
	const desktopMediaQuery = window.matchMedia('(min-width: 1200px)')
	const tabletMediaQuery = window.matchMedia('(min-width: 768px)')

	if (desktopMediaQuery.matches) {
		return desktop
	} else if (tabletMediaQuery.matches) {
		return tablet
	} else {
		return mobile
	}
}

// Listen for resize event
const deb = debounce(reinitializeLottieElements, 500)

let windowWidth = window.innerWidth
window.addEventListener('resize', () => {
	if (windowWidth !== window.innerWidth) {
		windowWidth = window.innerWidth
		deb()
	}
})

function isInViewport(element: Element) {
	// Get the bounding client rectangle position in the viewport
	const bounding = element.getBoundingClientRect()

	return bounding.top <= viewportHeight + offset
}

window.addEventListener(
	'scroll',
	function () {
		currentAnimations
			.filter(x => x.currentFrame === 0)
			.forEach(animation => {
				const animationAny = animation as unknown as any
				if (isInViewport(animationAny.wrapper)) {
					animation.play()
				}
			})
	},
	{
		passive: true,
	}
)
